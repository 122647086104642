import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material";

import { useTranslate } from "hooks/useTranslate.hook";

import { tutorialButtonConfig } from "config/configs";

export default function ModalTutorial({
  open,
  closeModal,
  title,
  textButtonOk,
  checked,
  onChangeChecked,
}) {
  const { t } = useTranslate();

  const handleOk = () => {
    closeModal();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleOk}>
        <DialogTitle>{`${title}`}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} mb={2}>
            {tutorialButtonConfig.map(({ icon, text, nameFile }) => (
              <Button
                key={icon}
                variant="outlined"
                size="small"
                startIcon={
                  <img
                    src={icon}
                    alt=""
                    width="24"
                    height="18"
                    style={{ objectFit: "cover" }}
                  />
                }
                color="dark"
                sx={{
                  textTransform: "none",
                  borderColor: (theme) => theme.palette.dark.main,
                  justifyContent: "flex-start",
                }}
                href={`/tutorials/${nameFile}`}
                download
              >
                {text}
              </Button>
            ))}
          </Stack>

          <FormControlLabel
            control={
              <Checkbox
                color="shief"
                checked={checked}
                onChange={onChangeChecked}
              />
            }
            label={t("DONT_SHOW_TUTORIAL")}
            sx={{ marginRight: 0 }}
          />
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleOk} autoFocus color="modalButton">
            {textButtonOk || t("OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
