import { Link as RouterLink } from "react-router-dom";

import { Link } from "@mui/material";

import { useTranslate } from "hooks";

const LinkPolicy = () => {
  const { t } = useTranslate();

  return (
    <>
      <Link
        component={RouterLink}
        to="/policy"
        state={{ type: "privacy-policy" }}
      >
        {t("PRIVACY_POLICY")}
      </Link>
      <br />
      <Link
        component={RouterLink}
        to="/policy"
        state={{ type: "terms-of-use" }}
      >
        {t("TERMS_OF_USE")}
      </Link>
      <br />
      <Link component={RouterLink} to="/policy" state={{ type: "impressum" }}>
        {t("IMPRESSUM")}
      </Link>
    </>
  );
};

export default LinkPolicy;
