import flagEng from "images/flagEng.svg";
import flagUa from "images/flagUa.svg";
import flagRu from "images/flagRu.svg";
import flagDe from "images/flagDe.svg";
import flagFr from "images/flagFr.svg";

export const navConfig = [
  { link: "/events", text: "EVENTS", icon: "SupervisorAccount", counter: 0 },
  { link: "/keys", text: "KEYS", icon: "VpnKey", counter: 0 },
  { link: "/menu", text: "MENU", icon: "Settings", counter: 0 },
];

export const redirectTypes = [
  {
    nameLink: "useLink",
    title: "ERROR_USELINK",
  },
  {
    nameLink: "useKey",
    title: "ERROR_USEKEY",
  },
];

export const DEFAULT_LANGUAGE = "eng";

export const langConfig = [
  {
    icon: flagDe,
    text: "DE",
    value: "de",
  },
  {
    icon: flagEng,
    text: "ENG",
    value: "eng",
  },
  {
    icon: flagFr,
    text: "FR",
    value: "fr",
  },
  {
    icon: flagUa,
    text: "UA",
    value: "ua",
  },
  {
    icon: flagRu,
    text: "RU",
    value: "rus",
  },
];

export const viewModeConfig = [
  {
    value: "table",
    icon: "TableChart",
  },
  {
    value: "calendar",
    icon: "CalendarMonth",
  },
];

export const startGeolocation = [50.4501, 30.5234];

export const alertsConfig = (action) => {
  switch (action) {
    case "new_participant":
      return {
        discription: "ADD_NEW_USER",
        icon: "SupervisorAccount",
      };
    case "participant_left":
      return {
        discription: "USER_REMOVED_THEMSELVES",
        icon: "PersonRemove",
      };
    case "event_deleted":
      return {
        discription: "EVENT_DELETED_BY",
        icon: "Remove",
      };
    case "key_used":
      return {
        discription: "USER_JOINED_EVENT_VIA_KEY",
        icon: "VpnKey",
      };
    default:
      return {
        icon: "Message",
      };
  }
};

export const tutorialButtonConfig = [
  {
    icon: flagDe,
    text: "Tutorial als PDF herunterladen",
    nameFile: "TerminNeu_Instruction_DE.pdf",
  },
  {
    icon: flagEng,
    text: "Download tutorial in PDF format",
    nameFile: "TerminNeu_Instruction_EN.pdf",
  },
  {
    icon: flagUa,
    text: "Завантажити туторіал у форматі PDF",
    nameFile: "TerminNeu_Instruction_UA.pdf",
  },
  // {
  //   icon: flagFr,
  //   text: "Téléchargez le tutoriel au format PDF",
  //   nameFile: "tutorial.pdf",
  // },
  {
    icon: flagRu,
    text: "Скачать туториал в формате PDF",
    nameFile: "TerminNeu_Instruction_RU.pdf",
  },
];
