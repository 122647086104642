import { useState, useEffect, useContext } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

import { useHttp } from "hooks/useHttp.hook";
import { ContextStore } from "context/ContextStore";

import { redirectTypes } from "config/configs";

import { passwordRegex } from "utils/common";

export const useLoginPage = () => {
  const { loading, request, error, clearError } = useHttp();
  const { login, lng, changeLanguage, notif } = useContext(ContextStore);
  const [activeTab, setActiveTab] = useState("login");
  const [form, setForm] = useState({
    email: "",
    fullName: "",
    password: "",
    confirmPassword: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isOpenModalForgot, setIsOpenModalForgot] = useState(false);

  useEffect(() => {
    if (location.search) {
      redirectTypes.forEach((item) => {
        if (searchParams.get(item.nameLink)) {
          setActiveTab("register");
          setModalTitle(item.title);
          setIsOpenModal(true);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
    clearError();
    // eslint-disable-next-line
  }, [error, clearError]);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const closeModalForgot = () => {
    setIsOpenModalForgot(false);
  };

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTabChange = (event, newValue) => {
    // !!!!!!!! remove blocck after prod
    setActiveTab(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !form.email ||
      !form.password ||
      (activeTab === "register" && (!form.fullName || !form.confirmPassword))
    ) {
      notif("ERROR_FILL_FIELDS", "error");
      return;
    }

    if (activeTab === "register" && !passwordRegex.test(form.password)) {
      notif("ERROR_REGISTER_PASS", "error");
      return;
    }

    try {
      let data;

      if (activeTab === "login") {
        data = await request("/api/auth/login", "POST", { ...form });
      } else {
        if (form.password !== form.confirmPassword) {
          notif("ERROR_NOTMATCH_PASS", "error");
          return;
        }
        data = await request("/api/auth/register", "POST", { ...form });
      }
      login(data.token, data.userId);

      if (location.state) {
        if (location.state.from?.pathname !== "/login") {
          navigate(location.state.from, { replace: true });
        }
      }
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const sendResetPassLink = async (email) => {
    try {
      const { message } = await request(
        "/api/auth/createResetPassLink",
        "POST",
        {
          email,
        }
      );
      notif(message, "alert");
    } catch (e) {
      notif(e.message, "error");
    }
  };

  return {
    activeTab,
    form,
    modalTitle,
    handleTabChange,
    handleSubmit,
    changeHandler,
    isOpenModalForgot,
    setIsOpenModalForgot,
    closeModalForgot,
    isOpenModal,
    closeModal,
    sendResetPassLink,
    loading,
    lng,
    changeLanguage,
  };
};
