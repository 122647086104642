import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { PrivateRoute } from "components/Elements";

import LoginPage from "pages/LoginPage/LoginPage";

import Messages from "pages/MessagesPage/MessagesPage";
import KeysPage from "pages/KeysPage/KeysPage";
import MenuPage from "pages/MenuPage/MenuPage";
import ConfirmPage from "pages/ConfirmPage/ConfirmPage";
import PolicyPage from "pages/PolicyPage/PolicyPage";
import AboutPage from "pages/AboutPage/AboutPage";

export const useRoutes = (isAuthentificated) => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            isAuthentificated ? (
              <Navigate to="/events" />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="login"
          element={
            isAuthentificated ? <Navigate to="/events" /> : <LoginPage />
          }
        />

        <Route
          path="/policy"
          element={<PolicyPage isAuthentificated={isAuthentificated} />}
        />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/confirm" element={<ConfirmPage />} />
        <Route element={<PrivateRoute isAllowed={isAuthentificated} />}>
          <Route path="/events" element={<Messages />} />
          <Route path="/keys" element={<KeysPage />} />
          <Route path="/menu" element={<MenuPage />} />

          <Route path="*" element={<p>PAGE NOT FOUND</p>} />
        </Route>
      </Routes>
    </>
  );
};
