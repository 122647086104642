import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { IconMuiDraw } from "components/Elements";

import { useTranslate } from "hooks";

import { dateDDMMYYYY, dateHHmm } from "utils/common";

import { alertsConfig } from "config/configs";

export default function ModalList({
  clearAllAlerts,
  open,
  closeModal,
  handleDelAlert,
  listAlerts,
  handleReadAlert,
}) {
  const { t } = useTranslate();

  const handleClose = () => {
    closeModal();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}> {t("NOTIFICATIONS")}</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <List>
          {listAlerts.map(({ id, message, read, date, action }) => {
            const { discription, icon } = alertsConfig(action);
            return (
              <ListItem
                key={id}
                secondaryAction={
                  <IconButton edge="end" onClick={() => handleDelAlert(id)}>
                    <DeleteIcon />
                  </IconButton>
                }
                sx={{
                  backgroundColor: (theme) =>
                    !read && theme.palette.gray.middle,
                  borderRadius: "6px",
                  marginBottom: "2px",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    onClick={() => handleReadAlert(id)}
                    sx={{
                      backgroundColor: (theme) => theme.palette.dark.main,
                    }}
                  >
                    <IconMuiDraw name={icon || "Message"} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  onClick={() => handleReadAlert(id)}
                  primary={t(discription, { user: message })}
                  secondary={`${dateDDMMYYYY(date)} ${dateHHmm(date)}`}
                  sx={{
                    "& .MuiListItemText-secondary": {
                      fontSize: "12px",
                    },
                    cursor: "pointer",
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={clearAllAlerts}
          color="modalButton"
          sx={{ marginRight: "auto" }}
        >
          {t("CLEAR_ALL")}
        </Button>
        <Button autoFocus onClick={handleClose} color="modalButton">
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
