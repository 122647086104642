export const AllText = {
  NONE404: {
    eng: "nullText",
    ua: "nullText",
    rus: "nullText",
    fr: "nullText",
    de: "nullText",
  },
  MAIN_HEADER: {
    eng: "TerminNeu",
    ua: "TerminNeu",
    rus: "TerminNeu",
    fr: "TerminNeu",
    de: "TerminNeu",
  },
  LOGIN: {
    eng: "Login",
    ua: "Логін",
    rus: "Вход",
    fr: "Connexion",
    de: "Anmelden",
  },
  REGISTER: {
    eng: "Register",
    ua: "Реєстрація",
    rus: "Регистрация",
    fr: "S'inscrire",
    de: "Registrieren",
  },
  EMAIL: {
    eng: "Email",
    ua: "Email",
    rus: "Email",
    fr: "Email",
    de: "Email",
  },
  PASSWORD: {
    eng: "Password",
    ua: "Пароль",
    rus: "Пароль",
    fr: "Mot de passe",
    de: "Passwort",
  },
  FULL_NAME: {
    eng: "Full Name",
    ua: "Повне ім'я",
    rus: "Полное имя",
    fr: "Nom complet",
    de: "Vollständiger Name",
  },
  CONFIRM_PASSWORD: {
    eng: "Confirm Password",
    ua: "Підтвердити пароль",
    rus: "Подтвердите пароль",
    fr: "Confirmer le mot de passe",
    de: "Passwort bestätigen",
  },
  CHANGE_LANGUAGE: {
    eng: "Change language",
    ua: "Змінити мову",
    rus: "Изменить язык",
    fr: "Changer de langue",
    de: "Sprache ändern",
  },
  EVENTS: {
    eng: "Events",
    ua: "Події",
    rus: "События",
    fr: "Événements",
    de: "Termine",
  },
  KEYS: {
    eng: "Keys",
    ua: "Ключі",
    rus: "Ключи",
    fr: "Clés",
    de: "Schlüssel",
  },
  MENU: {
    eng: "Menu",
    ua: "Меню",
    rus: "Меню",
    fr: "Menu",
    de: "Menü",
  },
  MESSAGE: {
    eng: "Message",
    ua: "Повідомлення",
    rus: "Сообщение",
    fr: "Message",
    de: "Nachricht",
  },

  EVENT_DATE: {
    eng: "Date",
    ua: "Дата",
    rus: "Дата",
    fr: "Date",
    de: "Datum",
  },
  NAME: {
    eng: "Name",
    ua: "Ім'я",
    rus: "Имя",
    fr: "Nom",
    de: "Name",
  },
  STATUS: {
    eng: "Status",
    ua: "Статус",
    rus: "Статус",
    fr: "Statut",
    de: "Status",
  },
  CANCEL: {
    eng: "Cancel",
    ua: "Скасувати",
    rus: "Отмена",
    fr: "Annuler",
    de: "Abbrechen",
  },
  OK: {
    eng: "Ok",
    ua: "Ok",
    rus: "Ok",
    fr: "Ok",
    de: "Ok",
  },
  SAVE: {
    eng: "Save",
    ua: "Зберегти",
    rus: "Сохранить",
    fr: "Enregistrer",
    de: "Speichern",
  },
  DELETE: {
    eng: "Delete",
    ua: "Видалити",
    rus: "Удалить",
    fr: "Supprimer",
    de: "Löschen",
  },
  LOGOUT: {
    eng: "Logout",
    ua: "Вихід",
    rus: "Выход",
    fr: "Déconnexion",
    de: "Abmelden",
  },
  CREATE_EVENT: {
    eng: "Create event",
    ua: "Створити подію",
    rus: "Создать событие",
    fr: "Créer un événement",
    de: "Termin erstellen",
  },
  CREATE: {
    eng: "Create",
    ua: "Створити",
    rus: "Создать",
    fr: "Créer",
    de: "Erstellen",
  },
  CREATE_KEY: {
    eng: "Create key",
    ua: "Створити ключ",
    rus: "Создать ключ",
    fr: "Créer une clé",
    de: "Schlüssel erstellen",
  },
  EXPIRED_DATE: {
    eng: "Expired Date",
    ua: "Дата закінчення терміну дії",
    rus: "Дата окончания срока действия",
    fr: "Date d'expiration",
    de: "Ablaufdatum",
  },
  EDIT_KEY: {
    eng: "Edit key",
    ua: "Редагувати ключ",
    rus: "Редактировать ключ",
    fr: "Modifier la clé",
    de: "Schlüssel bearbeiten",
  },
  ERROR_NAME_REQ: {
    eng: "Name is required",
    ua: "Ім'я є обов'язковим",
    rus: "Имя является обязательным",
    fr: "Le nom est requis",
    de: "Name ist erforderlich",
  },
  ERROR_EVENT_DATE_REQ: {
    eng: "Event Date is required",
    ua: "Дата події є обов'язковою",
    rus: "Дата события обязательна",
    fr: "La date de l'événement est requise",
    de: "Termindatum ist erforderlich",
  },
  ERROR_EXPIRED_DATE_REQ: {
    eng: "Expired Date is required",
    ua: "Дата закінчення терміну дії є обов'язковою",
    rus: "Дата окончания срока действия обязательна",
    fr: "La date d'expiration est requise",
    de: "Ablaufdatum ist erforderlich",
  },
  USAGE_COUNT: {
    eng: "Usage Count",
    ua: "Лічильник використання",
    rus: "Счетчик использования",
    fr: "Nombre d'utilisations",
    de: "Nutzungsanzahl",
  },
  UNLIMITED: {
    eng: "Unlimited",
    ua: "Необмежено",
    rus: "Неограниченный",
    fr: "Illimité",
    de: "Unbegrenzt",
  },
  STOP: {
    eng: "Stop",
    ua: "Стоп",
    rus: "Стоп",
    fr: "Arrêter",
    de: "Stoppen",
  },
  STOP_EVENT: {
    eng: "Stop Event",
    ua: "Зупинити подію",
    rus: "Остановить событие",
    fr: "Arrêter l'événement",
    de: "Termin stoppen",
  },
  WANT_STOP_EVENT: {
    eng: "Do you really want to stop the event?",
    ua: "Ви дійсно хочете зупинити подію?",
    rus: "Вы действительно хотите остановить событие?",
    fr: "Voulez-vous vraiment arrêter l'événement?",
    de: "Möchten Sie den Termin wirklich stoppen?",
  },
  DELETE_EVENT: {
    eng: "Delete Event",
    ua: "Видалити подію",
    rus: "Удалить событие",
    fr: "Supprimer l'événement",
    de: "Termin löschen",
  },
  WANT_DELETE_EVENT: {
    eng: "Do you really want to delete the event?",
    ua: "Ви дійсно хочете видалити подію?",
    rus: "Вы действительно хотите удалить событие?",
    fr: "Voulez-vous vraiment supprimer l'événement?",
    de: "Möchten Sie den Termin wirklich löschen?",
  },

  DELETE_KEY: {
    eng: "Delete Key",
    ua: "Видалити ключ",
    rus: "Удалить ключ",
    fr: "Supprimer la clé",
    de: "Schlüssel löschen",
  },
  WANT_DELETE_KEY: {
    eng: "Do you really want to delete the key?",
    ua: "Ви дійсно хочете видалити ключ?",
    rus: "Вы действительно хотите удалить ключ?",
    fr: "Voulez-vous vraiment supprimer la clé?",
    de: "Möchten Sie den Schlüssel wirklich löschen?",
  },
  SHARE: {
    eng: "Share",
    ua: "Поділитися",
    rus: "Поделиться",
    fr: "Partager",
    de: "Teilen",
  },
  USE_KEY: {
    eng: "Use Key",
    ua: "Використовувати ключ",
    rus: "Использовать ключ",
    fr: "Utiliser la clé",
    de: "Schlüssel verwenden",
  },
  CREATED_DATE: {
    eng: "Created date",
    ua: "Дата створення",
    rus: "Дата создания",
    fr: "Date de création",
    de: "Erstellungsdatum",
  },
  DAY: {
    eng: "day",
    ua: "день",
    rus: "день",
    fr: "jour",
    de: "Tag",
  },
  DAYS: {
    eng: "days",
    ua: "днів",
    rus: "дней",
    fr: "jours",
    de: "Tage",
  },
  TODAY: {
    eng: "Today",
    ua: "Сьогодні",
    rus: "Сегодня",
    fr: "Aujourd'hui",
    de: "Heute",
  },
  CANCELED: {
    eng: "Canceled",
    ua: "Скасовано",
    rus: "Отменено",
    fr: "Annulé",
    de: "Abgesagt",
  },
  PENDING: {
    eng: "Pending",
    ua: "Очікується",
    rus: "Ожидается",
    fr: "En attente",
    de: "Ausstehend",
  },
  SUCCESS: {
    eng: "Success",
    ua: "Успішно",
    rus: "Успешно",
    fr: "Succès",
    de: "Erfolg",
  },
  EXPIRED: {
    eng: "Expired",
    ua: "Закінчився",
    rus: "Истек",
    fr: "Expiré",
    de: "Abgelaufen",
  },
  DELETED: {
    eng: "Deleted",
    ua: "Видалено",
    rus: "Удалено",
    fr: "Supprimé",
    de: "Gelöscht",
  },
  UNKNOWN: {
    eng: "Unknown",
    ua: "Невідомо",
    rus: "Неизвестно",
    fr: "Inconnu",
    de: "Unbekannt",
  },
  CLOSE: {
    eng: "Close",
    ua: "Закрити",
    rus: "Закрыть",
    fr: "Fermer",
    de: "Schließen",
  },
  INV_EVENT: {
    eng: "Invitation to the event",
    ua: "Запрошення на подію",
    rus: "Приглашение на событие",
    fr: "Invitation à l'événement",
    de: "Einladung zum Termin",
  },
  ERROR_USELINK: {
    eng: "To use the link, log in to the app or register quickly",
    ua: "Для використання лінку увійдіть в додаток або пройдіть швидку реєстрацію",
    rus: "Для использования ссылки войдите в приложение или пройдите быструю регистрацию",
    fr: "Pour utiliser le lien, connectez-vous à l'application ou inscrivez-vous rapidement",
    de: "Um den Link zu verwenden, melden Sie sich in der App an oder registrieren Sie sich schnell",
  },
  ERROR_USEKEY: {
    eng: "To use the key, log in to the app or register quickly",
    ua: "Для використання ключа увійдіть в додаток або пройдіть швидку реєстрацію",
    rus: "Для использования ключа войдите в приложение или пройдите быструю регистрацию",
    fr: "Pour utiliser la clé, connectez-vous à l'application ou inscrivez-vous rapidement",
    de: "Um den Schlüssel zu verwenden, melden Sie sich in der App an oder registrieren Sie sich schnell",
  },
  NAME_KEY: {
    eng: "Name key",
    ua: "Назва ключа",
    rus: "Название ключа",
    fr: "Nom de la clé",
    de: "Schlüsselname",
  },
  CREATE_EVENT_FOR: {
    eng: "Create event for",
    ua: "Створити подію для",
    rus: "Создать событие для",
    fr: "Créer un événement pour",
    de: "Termin erstellen für",
  },
  KEY_LOADING: {
    eng: "Key loading…",
    ua: "Завантаження ключа…",
    rus: "Загрузка ключа…",
    fr: "Chargement de la clé…",
    de: "Schlüssel wird geladen…",
  },
  INVITATION: {
    eng: "Invitation",
    ua: "Запрошення",
    rus: "Приглашение",
    fr: "Invitation",
    de: "Einladung",
  },
  ACCEPT_INVITATION: {
    eng: "Accept the invitation?",
    ua: "Прийняти запрошення?",
    rus: "Принять приглашение?",
    fr: "Accepter l'invitation?",
    de: "Einladung annehmen?",
  },
  ACCEPT: {
    eng: "Accept",
    ua: "Прийняти",
    rus: "Принять",
    fr: "Accepter",
    de: "Akzeptieren",
  },
  ADD_KEY_TITLE: {
    eng: "Add Key",
    ua: "Додати ключ",
    rus: "Добавить ключ",
    fr: "Ajouter une clé",
    de: "Schlüssel hinzufügen",
  },
  WANT_ADD_KEY: {
    eng: "Do you want to add a key?",
    ua: "Ви хочете додати ключ?",
    rus: "Хотите добавить ключ?",
    fr: "Voulez-vous ajouter une clé?",
    de: "Möchten Sie einen Schlüssel hinzufügen?",
  },
  ADD_KEY: {
    eng: "Add Key",
    ua: "Додати ключ",
    rus: "Добавить ключ",
    fr: "Ajouter une clé",
    de: "Schlüssel hinzufügen",
  },

  ALL_KEYS: {
    eng: "All keys",
    ua: "Усі ключі",
    rus: "Все ключи",
    fr: "Toutes les clés",
    de: "Alle Schlüssel",
  },
  MY_KEY: {
    eng: "My keys",
    ua: "Мої ключі",
    rus: "Мои ключи",
    fr: "Mes clés",
    de: "Meine Schlüssel",
  },
  FRIENDS_KEYS: {
    eng: "Friends' keys",
    ua: "Ключі друзів",
    rus: "Ключи друзей",
    fr: "Clés d'amis",
    de: "Schlüssel von Freunden",
  },

  CONFIRM_MAIL: {
    eng: "Confirm mail",
    ua: "Підтвердьте пошту",
    rus: "Подтвердите почту",
    fr: "Confirmer le mail",
    de: "E-Mail bestätigen",
  },
  CONG_EMAIL_CONFIRMED: {
    eng: "Congratulations!!! Email confirmed.",
    ua: "Вітаємо! Електронну пошту підтверджено.",
    rus: "Поздравляем! Почта подтверждена.",
    fr: "Félicitations! E-mail confirmé.",
    de: "Herzlichen Glückwunsch! E-Mail bestätigt.",
  },
  CONFIRM_LINK_ERROR: {
    eng: "Confirm Link error",
    ua: "Помилка підтвердження посилання",
    rus: "Ошибка подтверждения ссылки",
    fr: "Erreur de confirmation du lien",
    de: "Bestätigungslink-Fehler",
  },
  CONTINUE: {
    eng: "Continue",
    ua: "Продовжити",
    rus: "Продолжить",
    fr: "Continuer",
    de: "Fortsetzen",
  },

  FORGOT_PASS: {
    eng: "Forgot a password?",
    ua: "Забули пароль?",
    rus: "Забыли пароль?",
    fr: "Mot de passe oublié ?",
    de: "Passwort vergessen?",
  },
  ENTER_MAIL: {
    eng: "Enter the email which you registered your account, which you want to restore now",
    ua: "Введіть електронну пошту, на яку ви зареєстрували свій обліковий запис, який хочете відновити",
    rus: "Введите электронную почту, на которую вы зарегистрировали свой аккаунт, который хотите восстановить",
    fr: "Entrez l'e-mail avec lequel vous avez enregistré votre compte que vous souhaitez restaurer maintenant",
    de: "Geben Sie die E-Mail ein, mit der Sie Ihr Konto registriert haben, das Sie jetzt wiederherstellen möchten",
  },
  SEND_LINK: {
    eng: "Send link",
    ua: "Надіслати посилання",
    rus: "Отправить ссылку",
    fr: "Envoyer le lien",
    de: "Link senden",
  },
  RESET_PASS_TITLE: {
    eng: "Reset Password",
    ua: "Скинути пароль",
    rus: "Сбросить пароль",
    fr: "Réinitialiser le mot de passe",
    de: "Passwort zurücksetzen",
  },
  RESET_PASWORD: {
    eng: "Reset Password",
    ua: "Скинути пароль",
    rus: "Сбросить пароль",
    fr: "Réinitialiser le mot de passe",
    de: "Passwort zurücksetzen",
  },

  PARTICIPANS: {
    eng: "Participants",
    ua: "Учасники",
    rus: "Участники",
    fr: "Participants",
    de: "Teilnehmer",
  },
  ONLY_PENDING: {
    eng: "Only pending",
    ua: "Тільки очікування",
    rus: "Только ожидается",
    fr: "Seulement en attente",
    de: "Nur ausstehend",
  },

  CODE_1: {
    eng: "Invalid login or password",
    ua: "Неправильний логін або пароль",
    rus: "Неверный логин или пароль",
    fr: "Identifiant ou mot de passe invalide",
    de: "Ungültiger Login oder Passwort",
  },

  CLICK_COPY_LINK: {
    eng: "Click to copy link",
    ua: "Натисніть, щоб скопіювати посилання",
    rus: "Нажмите, чтобы скопировать ссылку",
    fr: "Cliquez pour copier le lien",
    de: "Klicken Sie, um den Link zu kopieren",
  },
  LINK_COPY: {
    eng: "Link copy",
    ua: "Посилання скопійовано",
    rus: "Ссылка скопирована",
    fr: "Lien copié",
    de: "Link kopiert",
  },

  HOT_KEY_COPY: {
    eng: "Hot key copy",
    ua: "Гарячу клавішу скопійовано",
    rus: "Горячая клавиша скопирована",
    fr: "Raccourci copié",
    de: "Tastenkombination kopiert",
  },
  CREATE_HOT_CODE: {
    eng: "Create hot code",
    ua: "Створити гарячий код",
    rus: "Создать горячий код",
    fr: "Créer un code rapide",
    de: "Schnellcode erstellen",
  },

  QR_SCANNING: {
    eng: "QR code scanning",
    ua: "Сканування QR-коду",
    rus: "Сканирование QR-кода",
    fr: "Scan du code QR",
    de: "QR-Code-Scan",
  },

  INSERT_HOT_KEY: {
    eng: "Insert hot key",
    ua: "Введіть гарячу клавішу",
    rus: "Введите горячую клавишу",
    fr: "Insérer le raccourci",
    de: "Tastenkombination eingeben",
  },
  USE_HOT_KEY: {
    eng: "Use hot key",
    ua: "Використовуйте гарячу клавішу",
    rus: "Используйте горячую клавишу",
    fr: "Utiliser le raccourci",
    de: "Tastenkombination verwenden",
  },
  ERROR_KEY_REQ: {
    eng: "Key is required",
    ua: "Ключ обов'язковий",
    rus: "Требуется ключ",
    fr: "Clé requise",
    de: "Schlüssel erforderlich",
  },

  FOLLOW_LINK: {
    eng: "Follow the link",
    ua: "Перейдіть за посиланням",
    rus: "Перейдите по ссылке",
    fr: "Suivez le lien",
    de: "Folgen Sie dem Link",
  },
  LOADING: {
    eng: "Loading…",
    ua: "Завантаження…",
    rus: "Загрузка…",
    fr: "Chargement…",
    de: "Laden…",
  },

  EMAIL_CONFIRMED: {
    eng: "Email confirmed",
    ua: "Електронна пошта підтверджена",
    rus: "Электронная почта подтверждена",
    fr: "E-mail confirmé",
    de: "E-Mail bestätigt",
  },
  ADD_EVENT_YOURSELF: {
    eng: "Add an event for yourself",
    ua: "Додайте подію для себе",
    rus: "Добавьте событие для себя",
    fr: "Ajoutez un événement pour vous-même",
    de: "Fügen Sie einen Termin für sich selbst hinzu.",
  },

  INCORRECT_QR: {
    eng: "Incorrect QR code",
    ua: "Неправильний QR-код",
    rus: "Неправильный QR-код",
    fr: "Code QR incorrect",
    de: "Falscher QR-Code",
  },

  ORGANIZER: {
    eng: "Organizer",
    ua: "Організатор",
    rus: "Организатор",
    fr: "Organisateur",
    de: "Organisator",
  },
  CLEAR_ALL: {
    eng: "Clear All",
    ua: "Очистити все",
    rus: "Очистить все",
    fr: "Tout effacer",
    de: "Alles löschen",
  },
  NOTIFICATIONS: {
    eng: "Notifications",
    ua: "Сповіщення",
    rus: "Уведомления",
    fr: "Notifications",
    de: "Benachrichtigungen",
  },

  EVENT_ORGANIZE: {
    eng: "I am the event organizer",
    ua: "Я організатор події",
    rus: "Я организатор события",
    fr: "Je suis l'organisateur de l'événement",
    de: "Ich bin der Veranstalter des Termins",
  },

  ADD_ADDRESS: {
    eng: "Add address",
    ua: "Додати адресу",
    rus: "Добавить адрес",
    fr: "Ajouter une adresse",
    de: "Adresse hinzufügen",
  },
  ADDRESS: {
    eng: "Address",
    ua: "Адреса",
    rus: "Адрес",
    fr: "Adresse",
    de: "Adresse",
  },
  ERROR_ADDRESS: {
    eng: "The address was not found on the map but you can save the entered text",
    ua: "Адресу не знайдено на карті, але ви можете зберегти введений текст",
    rus: "Адрес не найден на карте, но вы можете сохранить введённый текст",
    fr: "L'adresse n'a pas été trouvée sur la carte mais vous pouvez enregistrer le texte saisi",
    de: "Die Adresse wurde auf der Karte nicht gefunden, aber Sie können den eingegebenen Text speichern",
  },

  ERROR_FILL_FIELDS: {
    eng: "Please fill in all fields",
    ua: "Будь ласка, заповніть усі поля",
    rus: "Пожалуйста, заполните все поля",
    fr: "Veuillez remplir tous les champs",
    de: "Bitte füllen Sie alle Felder aus",
  },
  ERROR_REGISTER_PASS: {
    eng: "The password should be between 8 and 16 characters long and contain only Latin letters, numbers, or special characters (without quotation marks)",
    ua: "Пароль має бути довжиною від 8 до 16 символів і містити лише латинські букви, цифри або спеціальні символи (без лапок)",
    rus: "Пароль должен быть длиной от 8 до 16 символов и содержать только латинские буквы, цифры или специальные символы (без кавычек)",
    fr: "Le mot de passe doit comporter entre 8 et 16 caractères et contenir uniquement des lettres latines, des chiffres ou des caractères spéciaux (sans guillemets)",
    de: "Das Passwort muss zwischen 8 und 16 Zeichen lang sein und nur lateinische Buchstaben, Zahlen oder Sonderzeichen enthalten (ohne Anführungszeichen)",
  },

  ERROR_NOTMATCH_PASS: {
    eng: "Passwords do not match",
    ua: "Паролі не збігаються",
    rus: "Пароли не совпадают",
    fr: "Les mots de passe ne correspondent pas",
    de: "Passwörter stimmen nicht überein",
  },
  SUPPORT: {
    eng: "Support",
    ua: "Підтримка",
    rus: "Поддержка",
    fr: "Support",
    de: "Unterstützung",
  },
  USE_FORM_SUPPORT: {
    eng: "Use the form to reach out to our support team with any questions or issues",
    ua: "Скористайтеся формою, щоб зв’язатися з нашою командою підтримки з будь-якими питаннями чи проблемами",
    rus: "Используйте форму, чтобы связаться с нашей командой поддержки с любыми вопросами или проблемами",
    fr: "Utilisez le formulaire pour contacter notre équipe de support pour toute question ou problème",
    de: "Verwenden Sie das Formular, um unser Support-Team mit Fragen oder Problemen zu kontaktieren",
  },
  EMAIL_SUPPORT: {
    eng: "Email",
    ua: "Електронна пошта",
    rus: "Электронная почта",
    fr: "E-mail",
    de: "E-Mail",
  },
  SUPPORT_TITLE: {
    eng: "Support",
    ua: "Підтримка",
    rus: "Поддержка",
    fr: "Support",
    de: "Unterstützung",
  },
  DESCRIBE_PROBLEM: {
    eng: "Please, describe your problem",
    ua: "Будь ласка, опишіть вашу проблему",
    rus: "Пожалуйста, опишите вашу проблему",
    fr: "Veuillez décrire votre problème",
    de: "Bitte beschreiben Sie Ihr Problem",
  },
  MESSAGE_SUPPORT: {
    eng: "Your message to support",
    ua: "Ваше повідомлення до служби підтримки",
    rus: "Ваше сообщение в службу поддержки",
    fr: "Votre message au support",
    de: "Ihre Nachricht an den Support",
  },
  SEND: {
    eng: "Send",
    ua: "Відправити",
    rus: "Отправить",
    fr: "Envoyer",
    de: "Senden",
  },
  ERROR_EMAIL_REQ: {
    eng: "Email is required",
    ua: "Електронна пошта обов'язкова",
    rus: "Электронная почта обязательна",
    fr: "E-mail requis",
    de: "E-Mail erforderlich",
  },
  ERROR_MESSAGE_REQ: {
    eng: "Message is required",
    ua: "Повідомлення обов'язкове",
    rus: "Сообщение обязательно",
    fr: "Message requis",
    de: "Nachricht erforderlich",
  },
  ADDRESS_NOT_FOUND: {
    eng: "Address not found on the map (check with the event creator)",
    ua: "Адресу не знайдено на карті (перевірте у організатора події)",
    rus: "Адрес не найден на карте (уточните у создателя события)",
    fr: "Adresse introuvable sur la carte (vérifiez auprès du créateur de l'événement)",
    de: "Adresse auf der Karte nicht gefunden (bitte beim Termin-Organisator nachfragen)",
  },
  ENABLE_LIVE_QUEUE: {
    eng: "Enable Live Queue",
    ua: "Увімкнути живу чергу",
    rus: "Включить живую очередь",
    fr: "Activer la file d'attente en direct",
    de: "Live-Warteschlange aktivieren",
  },
  ONLY_LIVE_QUEUE: {
    eng: "Only Live Queue",
    ua: "Тільки жива черга",
    rus: "Только живая очередь",
    fr: "Seulement la file d'attente en direct",
    de: "Nur Live-Warteschlange",
  },
  INVITE: {
    eng: "Invite",
    ua: "Запросити",
    rus: "Пригласить",
    fr: "Inviter",
    de: "Einladen",
  },
  FINISH: {
    eng: "Finish",
    ua: "Завершити",
    rus: "Завершить",
    fr: "Terminer",
    de: "Fertigstellen",
  },
  INVITE_USER: {
    eng: "Invite user",
    ua: "Запросити користувача",
    rus: "Пригласить пользователя",
    fr: "Inviter un utilisateur",
    de: "Benutzer einladen",
  },
  END_MEETING: {
    eng: "End the meeting with the",
    ua: "Завершити зустріч з",
    rus: "Завершить встречу с",
    fr: "Terminer la réunion avec",
    de: "Das Meeting mit beenden",
  },
  YOUR_QUEUE_IN: {
    eng: "Your queue in",
    ua: "Ваша черга в",
    rus: "Ваша очередь в",
    fr: "Votre file d'attente dans",
    de: "Ihre Warteschlange in",
  },
  PERSONS: {
    eng: "persons",
    ua: "осіб",
    rus: "человек",
    fr: "personnes",
    de: "Personen",
  },
  PERSON: {
    eng: "person",
    ua: "особа",
    rus: "человек",
    fr: "personne",
    de: "Person",
  },
  YOUR_TURN: {
    eng: "Your turn",
    ua: "Ваша черга",
    rus: "Ваш ход",
    fr: "Votre tour",
    de: "Ihr Zug",
  },
  CURRENT_NUMBER: {
    eng: "Current number",
    ua: "Поточний номер",
    rus: "Текущий номер",
    fr: "Numéro actuel",
    de: "Aktuelle Nummer",
  },
  MY_NUMBER: {
    eng: "My number",
    ua: "Мій номер",
    rus: "Мой номер",
    fr: "Mon numéro",
    de: "Meine Nummer",
  },
  TO_CHANGE_PASS: {
    eng: "To change the password, you need to log out of your account and follow the password reset link again.",
    ua: "Щоб змінити пароль, потрібно вийти з акаунту і знову перейти за посиланням для скидання паролю.",
    rus: "Чтобы изменить пароль, необходимо выйти из аккаунта и снова пройти по ссылке для сброса пароля.",
    fr: "Pour changer le mot de passe, vous devez vous déconnecter de votre compte et suivre à nouveau le lien de réinitialisation du mot de passe.",
    de: "Um das Passwort zu ändern, müssen Sie sich aus Ihrem Konto abmelden und dem Link zum Zurücksetzen des Passworts erneut folgen.",
  },
  TUTORIAL: {
    eng: "Tutorial",
    ua: "Посібник",
    rus: "Руководство",
    fr: "Tutoriel",
    de: "Tutorial",
  },
  TUTORIAL_TITLE: {
    eng: "Tutorial",
    ua: "Посібник",
    rus: "Руководство",
    fr: "Tutoriel",
    de: "Tutorial",
  },
  DONT_SHOW_TUTORIAL: {
    eng: "Don’t show this tutorial again",
    ua: "Не показувати цей посібник знову",
    rus: "Не показывать это руководство снова",
    fr: "Ne montrez plus ce tutoriel",
    de: "Zeigen Sie dieses Tutorial nicht mehr",
  },
  ERROR_001: {
    eng: "User already exists",
    ua: "Користувач вже існує",
    rus: "Пользователь уже существует",
    fr: "L'utilisateur existe déjà",
    de: "Benutzer existiert bereits",
  },
  ERROR_002: {
    eng: "An incorrectly entered password or login",
    ua: "Неправильно введений пароль або логін",
    rus: "Некорректно введен пароль или логин",
    fr: "Mot de passe ou identifiant incorrect",
    de: "Falsch eingegebenes Passwort oder Login",
  },

  // allert from backend
  // !!!!!!!!!!!!!!!!!!!
  NOT_AUTHORIZATION: {
    eng: "Not authorization",
    ua: "Немає авторизації",
    rus: "Нет авторизации",
    fr: "Pas d'autorisation",
    de: "Keine Autorisierung",
  },

  USAGE_COUNT_VALIDATION: {
    eng: "usageCount must be a number or 'unlimited'",
    ua: "usageCount має бути числом або 'unlimited'",
    rus: "usageCount должен быть числом или 'unlimited'",
    fr: "usageCount doit être un nombre ou 'unlimited'",
    de: "usageCount muss eine Zahl oder 'unlimited' sein",
  },

  INVALID_REGISTRATION_DATA: {
    eng: "Invalid registration data",
    ua: "Недійсні реєстраційні дані",
    rus: "Недействительные регистрационные данные",
    fr: "Données d'enregistrement invalides",
    de: "Ungültige Registrierungsdaten",
  },
  USER_ALREADY_EXISTS: {
    eng: "User already exists",
    ua: "Користувач вже існує",
    rus: "Пользователь уже существует",
    fr: "L'utilisateur existe déjà",
    de: "Benutzer existiert bereits",
  },

  SERVER_ERROR: {
    eng: "Server Error",
    ua: "Помилка сервера",
    rus: "Ошибка сервера",
    fr: "Erreur du serveur",
    de: "Serverfehler",
  },

  INVALID_LOGIN_DATA: {
    eng: "Incorrect login data",
    ua: "Некоректні дані для входу",
    rus: "Некорректные данные при входе в систему",
    fr: "Données de connexion incorrectes",
    de: "Falsche Anmeldedaten",
  },

  INVALID_LOGIN_OR_PASSWORD: {
    eng: "Invalid login or password",
    ua: "Недійсний логін або пароль",
    rus: "Неверный логин или пароль",
    fr: "Identifiant ou mot de passe invalide",
    de: "Ungültiger Login oder Passwort",
  },

  USER_NOT_FOUND_BY_EMAIL: {
    eng: "User with the specified email address not found",
    ua: "Користувача з вказаною електронною адресою не знайдено",
    rus: "Пользователь с указанным адресом электронной почты не найден",
    fr: "Utilisateur avec l'adresse e-mail spécifiée non trouvé",
    de: "Benutzer mit der angegebenen E-Mail-Adresse nicht gefunden",
  },

  EMAIL_ALREADY_VERIFIED: {
    eng: "Email address already verified",
    ua: "Адресу електронної пошти вже підтверджено",
    rus: "Адрес электронной почты уже подтвержден",
    fr: "Adresse e-mail déjà vérifiée",
    de: "E-Mail-Adresse bereits bestätigt",
  },

  CONFIRMATION_EMAIL_SENT: {
    eng: "Confirmation email sent to the specified email address",
    ua: "Лист із підтвердженням надіслано на вказану електронну адресу",
    rus: "Письмо с подтверждением отправлено на указанный адрес электронной почты",
    fr: "E-mail de confirmation envoyé à l'adresse e-mail spécifiée",
    de: "Bestätigungs-E-Mail an die angegebene Adresse gesendet",
  },

  CONFIRMATION_EMAIL_CREATION_ERROR: {
    eng: "Error creating confirmation email",
    ua: "Помилка при створенні листа з підтвердженням",
    rus: "Ошибка при создании письма с подтверждением",
    fr: "Erreur lors de la création de l'e-mail de confirmation",
    de: "Fehler beim Erstellen der Bestätigungs-E-Mail",
  },

  USER_NOT_FOUND_BY_CONFIRMATION_KEY: {
    eng: "User with the specified confirmation key not found",
    ua: "Користувача з вказаним ключем підтвердження не знайдено",
    rus: "Пользователь с указанным ключом подтверждения не найден",
    fr: "Utilisateur avec la clé de confirmation spécifiée non trouvé",
    de: "Benutzer mit dem angegebenen Bestätigungsschlüssel nicht gefunden",
  },

  CONFIRMATION_KEY_EXPIRED: {
    eng: "The confirmation key has expired",
    ua: "Термін дії ключа підтвердження закінчився",
    rus: "Срок действия ключа подтверждения истек",
    fr: "La clé de confirmation a expiré",
    de: "Der Bestätigungsschlüssel ist abgelaufen",
  },

  EMAIL_SUCCESSFULLY_VERIFIED: {
    eng: "Email successfully verified",
    ua: "Електронну пошту успішно підтверджено",
    rus: "Email успешно подтвержден",
    fr: "E-mail vérifié avec succès",
    de: "E-Mail erfolgreich bestätigt",
  },

  EMAIL_VERIFICATION_ERROR: {
    eng: "Error verifying email",
    ua: "Помилка при підтвердженні електронної пошти",
    rus: "Ошибка при подтверждении email",
    fr: "Erreur lors de la vérification de l'e-mail",
    de: "Fehler bei der E-Mail-Bestätigung",
  },

  PASSWORD_RESET_EMAIL_SENT: {
    eng: "Email with password reset link sent to the provided email address",
    ua: "Лист із посиланням для скидання пароля надіслано на вказану електронну адресу",
    rus: "Письмо с ссылкой для сброса пароля отправлено на указанный адрес электронной почты",
    fr: "E-mail avec le lien de réinitialisation du mot de passe envoyé à l'adresse e-mail fournie",
    de: "E-Mail mit Link zum Zurücksetzen des Passworts an die angegebene Adresse gesendet",
  },

  EMAIL_SENT: {
    eng: "Email sent",
    ua: "Електронний лист надіслано",
    rus: "Письмо отправлено",
    fr: "E-mail envoyé",
    de: "E-Mail gesendet",
  },

  PASSWORD_RESET_KEY_NOT_FOUND: {
    eng: "Password reset key not found",
    ua: "Ключ скидання пароля не знайдено",
    rus: "Ключ сброса пароля не найден",
    fr: "Clé de réinitialisation du mot de passe non trouvée",
    de: "Passwort-Zurücksetzungs-Schlüssel nicht gefunden",
  },

  PASSWORD_RESET_KEY_EXPIRED: {
    eng: "Password reset key has expired",
    ua: "Термін дії ключа скидання пароля закінчився",
    rus: "Срок действия ключа сброса пароля истек",
    fr: "La clé de réinitialisation du mot de passe a expiré",
    de: "Der Passwort-Zurücksetzungs-Schlüssel ist abgelaufen",
  },

  PASSWORD_CHANGED_SUCCESSFULLY: {
    eng: "Password changed successfully. Please log in with your new password.",
    ua: "Пароль успішно змінено. Будь ласка, увійдіть з новим паролем.",
    rus: "Пароль успешно изменен. Пожалуйста, войдите с новым паролем.",
    fr: "Mot de passe changé avec succès. Veuillez vous connecter avec votre nouveau mot de passe.",
    de: "Passwort erfolgreich geändert. Bitte melden Sie sich mit Ihrem neuen Passwort an.",
  },

  USER_NOT_FOUND: {
    eng: "User not found",
    ua: "Користувача не знайдено",
    rus: "Пользователь не найден",
    fr: "Utilisateur non trouvé",
    de: "Benutzer nicht gefunden",
  },

  LED_SETTINGS_SAVED: {
    eng: "LED settings saved successfully",
    ua: "Налаштування LED успішно збережено",
    rus: "Настройки LED успешно сохранены",
    fr: "Paramètres LED enregistrés avec succès",
    de: "LED-Einstellungen erfolgreich gespeichert",
  },

  LED_SETTINGS_SAVE_ERROR: {
    eng: "Error while saving LED settings",
    ua: "Помилка під час збереження налаштувань LED",
    rus: "Ошибка при сохранении настроек LED",
    fr: "Erreur lors de l'enregistrement des paramètres LED",
    de: "Fehler beim Speichern der LED-Einstellungen",
  },

  DEVICE_KEY_REQUIRED: {
    eng: "Device Key is required",
    ua: "Потрібен ключ пристрою",
    rus: "Требуется ключ устройства",
    fr: "La clé de l'appareil est requise",
    de: "Geräteschlüssel ist erforderlich",
  },

  SETTINGS_NOT_FOUND: {
    eng: "Settings not found",
    ua: "Налаштування не знайдено",
    rus: "Настройки не найдены",
    fr: "Paramètres non trouvés",
    de: "Einstellungen nicht gefunden",
  },

  INVALID_KEY: {
    eng: "Invalid key",
    ua: "Недійсний ключ",
    rus: "Недействительный ключ",
    fr: "Clé invalide",
    de: "Ungültiger Schlüssel",
  },

  USAGE_COUNT_EXHAUSTED: {
    eng: "Usage count exhausted",
    ua: "Вичерпано ліміт використання",
    rus: "Лимит использования исчерпан",
    fr: "Le nombre d'utilisations est épuisé",
    de: "Verbrauchszähler erschöpft",
  },

  EVENT_NOT_FOUND_OR_DELETED: {
    eng: "Event not found or deleted",
    ua: "Подію не знайдено або видалено",
    rus: "Событие не найдено или удалено",
    fr: "Événement non trouvé ou supprimé",
    de: "Termin nicht gefunden oder gelöscht",
  },

  EVENT_NOT_FOUND: {
    eng: "Event not found",
    ua: "Подію не знайдено",
    rus: "Событие не найдено",
    fr: "Événement non trouvé",
    de: "Termin nicht gefunden",
  },

  FORBIDDEN_NOT_EVENT_OWNER: {
    eng: "Forbidden: You are not the owner of the event",
    ua: "Заборонено: Ви не є власником події",
    rus: "Запрещено: Вы не являетесь владельцем события",
    fr: "Interdit : Vous n'êtes pas le propriétaire de l'événement",
    de: "Verboten: Sie sind nicht der Besitzer des Termins",
  },

  USER_IS_EVENT_OWNER: {
    eng: "User is the owner of the event",
    ua: "Користувач є власником події",
    rus: "Пользователь является владельцем события",
    fr: "L'utilisateur est le propriétaire de l'événement",
    de: "Der Benutzer ist der Besitzer des Termins",
  },

  USER_ALREADY_ADDED_TO_EVENT: {
    eng: "User already added to the event",
    ua: "Користувач вже доданий до події",
    rus: "Пользователь уже добавлен в событие",
    fr: "L'utilisateur est déjà ajouté à l'événement",
    de: "Benutzer wurde bereits zum Termin hinzugefügt",
  },

  EVENT_NOT_AVAILABLE: {
    eng: "Event is not available",
    ua: "Подія недоступна",
    rus: "Событие недоступно",
    fr: "L'événement n'est pas disponible",
    de: "Der Termin ist nicht verfügbar",
  },

  HOT_KEY_EDITED: {
    eng: "Hot Key edited",
    ua: "Гарячий ключ відредагований",
    rus: "Hot Key отредактирован",
    fr: "Clé de raccourci modifiée",
    de: "Hotkey-Schlüssel bearbeitet",
  },

  HOT_KEY_REQUIRED: {
    eng: "Hot Key is required",
    ua: "Гарячий ключ є обов'язковим",
    rus: "Требуется Hot Key",
    fr: "La clé de raccourci est requise",
    de: "Hot Key - Schlüssel ist erforderlich",
  },

  HOT_KEY_NOT_FOUND_OR_USED: {
    eng: "Hot Key not found or already used",
    ua: "Гарячий ключ не знайдено або вже використано",
    rus: "Hot Key не найден или уже использован",
    fr: "Clé de raccourci non trouvée ou déjà utilisée",
    de: "Hotkey-Schlüssel nicht gefunden oder bereits verwendet",
  },

  CANNOT_USE_OWN_HOT_KEY: {
    eng: "You cannot use your own Hot Key",
    ua: "Ви не можете використовувати власний гарячий ключ",
    rus: "Вы не можете использовать собственный Hot Key",
    fr: "Vous ne pouvez pas utiliser votre propre clé de raccourci",
    de: "Sie können Ihren eigenen Hot Key - Schlüssel nicht verwenden",
  },

  INVALID_HOT_KEY: {
    eng: "Invalid Hot Key",
    ua: "Недійсний гарячий ключ",
    rus: "Неправильный Хот Кей",
    fr: "Clé de raccourci invalide",
    de: "Ungültiger Hotkey-Schlüssel",
  },

  HOT_KEY_SUCCESSFULLY_USED: {
    eng: "Hot Key successfully used",
    ua: "Гарячий ключ успішно використано",
    rus: "Хот Кей успешно использован",
    fr: "Clé de raccourci utilisée avec succès",
    de: "Hotkey-Schlüssel erfolgreich verwendet",
  },

  NOTIFICATION_NOT_FOUND: {
    eng: "Notification not found",
    ua: "Сповіщення не знайдено",
    rus: "Уведомление не найдено",
    fr: "Notification non trouvée",
    de: "Benachrichtigung nicht gefunden",
  },

  NOTIFICATION_DELETED: {
    eng: "Notification deleted",
    ua: "Сповіщення видалено",
    rus: "Уведомление удалено",
    fr: "Notification supprimée",
    de: "Benachrichtigung gelöscht",
  },

  NOTIFICATION_MARKED_AS_READ: {
    eng: "Notification marked as read",
    ua: "Сповіщення позначено як прочитане",
    rus: "Уведомление отмечено как прочитанное",
    fr: "Notification marquée comme lue",
    de: "Benachrichtigung als gelesen markiert",
  },

  ALL_NOTIFICATIONS_DELETED: {
    eng: "All notifications deleted",
    ua: "Усі сповіщення видалено",
    rus: "Все уведомления удалены",
    fr: "Toutes les notifications supprimées",
    de: "Alle Benachrichtigungen gelöscht",
  },

  KEY_NOT_FOUND_OR_DELETED: {
    eng: "Key not found or deleted",
    ua: "Ключ не знайдено або видалено",
    rus: "Ключ не найден или удалён",
    fr: "Clé non trouvée ou supprimée",
    de: "Schlüssel nicht gefunden oder gelöscht",
  },

  PERMISSION_DENIED: {
    eng: "Permission denied",
    ua: "Доступ заборонено",
    rus: "Доступ запрещен",
    fr: "Permission refusée",
    de: "Zugriff verweigert",
  },

  KEY_DELETED_SUCCESSFULLY: {
    eng: "Key deleted successfully",
    ua: "Ключ успішно видалено",
    rus: "Ключ успешно удалён",
    fr: "Clé supprimée avec succès",
    de: "Schlüssel erfolgreich gelöscht",
  },

  KEY_NOT_FOUND: {
    eng: "Key not found",
    ua: "Ключ не знайдено",
    rus: "Ключ не найден",
    fr: "Clé non trouvée",
    de: "Schlüssel nicht gefunden",
  },

  ACCESS_DENIED: {
    eng: "Access denied",
    ua: "Доступ заборонено",
    rus: "Доступ запрещен",
    fr: "Accès refusé",
    de: "Zugriff verweigert",
  },

  ERROR_KEY: {
    eng: "Error key",
    ua: "Помилковий ключ",
    rus: "Ошибка ключа",
    fr: "Clé d'erreur",
    de: "Fehlerschlüssel",
  },

  KEY_EXPIRED: {
    eng: "Key has expired",
    ua: "Час дії ключа закінчився",
    rus: "Время ключа истекло",
    fr: "La clé a expiré",
    de: "Schlüssel ist abgelaufen",
  },

  KEY_USAGE_EXHAUSTED: {
    eng: "Key usage exhausted",
    ua: "Кількість використань ключа вичерпано",
    rus: "Количество использования ключа исчерпано",
    fr: "Utilisation de la clé épuisée",
    de: "Schlüsselverwendung erschöpft",
  },

  USER_IS_KEY_OWNER: {
    eng: "User is the owner of the key",
    ua: "Користувач є власником ключа",
    rus: "Пользователь является владельцем ключа",
    fr: "L'utilisateur est le propriétaire de la clé",
    de: "Der Benutzer ist der Besitzer des Schlüssels",
  },

  USER_ALREADY_ADDED_TO_KEY: {
    eng: "User already added to the key",
    ua: "Користувач вже доданий до ключа",
    rus: "Пользователь уже добавлен к ключу",
    fr: "L'utilisateur est déjà ajouté à la clé",
    de: "Benutzer bereits zum Schlüssel hinzugefügt",
  },

  KEY_NOT_AVAILABLE: {
    eng: "Key is not available",
    ua: "Ключ недоступний",
    rus: "Ключ недоступен",
    fr: "La clé n'est pas disponible",
    de: "Schlüssel ist nicht verfügbar",
  },

  ADD_NEW_USER: {
    eng: "Add new user {{user}}",
    ua: "Додати нового користувача {{user}}",
    rus: "Добавить нового пользователя {{user}}",
    fr: "Ajouter un nouvel utilisateur {{user}}",
    de: "Neuen Benutzer hinzufügen {{user}}",
  },

  USER_REMOVED_THEMSELVES: {
    eng: "User {{user}} removed themselves from the Event",
    ua: "Користувач {{user}} самостійно покинув подію",
    rus: "Пользователь {{user}} самостоятельно вышел из события",
    fr: "L'utilisateur {{user}} s'est retiré de l'événement",
    de: "Benutzer {{user}} hat sich selbst vom Termin entfernt",
  },

  REMOVED_THEMSELVES: {
    eng: "removed themselves from the Event",
    ua: "самостійно покинув подію",
    rus: "самостоятельно вышел из события",
    fr: "s'est retiré de l'événement",
    de: "hat den Termin selbst verlassen",
  },

  EVENT_DELETED_BY: {
    eng: "Event deleted by {{user}}",
    ua: "Подію видалив {{user}}",
    rus: "Событие удалено пользователем {{user}}",
    fr: "Événement supprimé par {{user}}",
    de: "Termin wurde von {{user}} gelöscht",
  },

  USER_JOINED_EVENT_VIA_KEY: {
    eng: "User {{user}} joined event via key",
    ua: "Користувач {{user}} приєднався до події через ключ",
    rus: "Пользователь {{user}} присоединился к событию через ключ",
    fr: "L'utilisateur {{user}} a rejoint l'événement via une clé",
    de: "Benutzer {{user}} ist dem Termin über einen Schlüssel beigetreten",
  },

  AGREE_TO_PRIVACY_TERMS1: {
    eng: "I agree to the ",
    ua: "Я погоджуюсь з ",
    rus: "Я согласен с ",
    fr: "J'accepte la ",
    de: "Ich stimme der ",
  },

  AGREE_TO_PRIVACY_TERMS2: {
    eng: " and ",
    ua: " та ",
    rus: " и ",
    fr: " et les ",
    de: " und den ",
  },

  PRIVACY_POLICY_LINK: {
    eng: "Privacy Policy",
    ua: "Політикою конфіденційності",
    rus: "Политикой конфиденциальности",
    fr: "Politique de confidentialité",
    de: "Datenschutzrichtlinie",
  },

  TERMS_OF_USE_LINK: {
    eng: "Terms of Use",
    ua: "Умовами використання",
    rus: "Условиями использования",
    fr: "Conditions d'utilisation",
    de: "Nutzungsbedingungen  zu",
  },

  AGREE_TO_PRIVACY_TERMS: {
    eng: "I agree to the Privacy Policy and Terms of Use",
    ua: "Я погоджуюсь з Політикою конфіденційності та Умовами використання",
    rus: "Я согласен с Политикой конфиденциальности и Условиями использования",
    fr: "J'accepte la Politique de confidentialité et les Conditions d'utilisation",
    de: "Ich stimme der Datenschutzrichtlinie und den Nutzungsbedingungen zu",
  },

  BETA_VERSION_WARNING: {
    eng: "This is a beta version, errors may occur. More details here.",
    ua: "Це бета-версія, можливі помилки. Докладніше тут.",
    rus: "Это бета-версия, возможны ошибки. Подробнее здесь.",
    fr: "Ceci est une version bêta, des erreurs peuvent survenir. Plus de détails ici.",
    de: "Dies ist eine Beta-Version, Fehler können auftreten. Mehr Details hier.",
  },

  PRIVACY_POLICY: {
    eng: "Privacy Policy",
    ua: "Політика конфіденційності",
    rus: "Политика конфиденциальности",
    fr: "Politique de confidentialité",
    de: "Datenschutzrichtlinie",
  },

  TERMS_OF_USE: {
    eng: "Terms of Use",
    ua: "Умови використання",
    rus: "Условия использования",
    fr: "Conditions d'utilisation",
    de: "Nutzungsbedingungen",
  },

  IMPRESSUM: {
    eng: "Impressum",
    ua: "Вихідні дані",
    rus: "Импрессум",
    fr: "Mentions légales",
    de: "Impressum",
  },

  AGREE_BY_USING_SERVICE: {
    eng: "By using this service, you agree to the Privacy Policy and Terms of Use.",
    ua: "Використовуючи цей сервіс, ви погоджуєтесь з Політикою конфіденційності та Умовами використання.",
    rus: "Используя этот сервис, вы соглашаетесь с Политикой конфиденциальности и Условиями использования.",
    fr: "En utilisant ce service, vous acceptez la Politique de confidentialité et les Conditions d'utilisation.",
    de: "Durch die Nutzung dieses Dienstes stimmen Sie der Datenschutzrichtlinie und den Nutzungsbedingungen zu.",
  },

  COPYRIGHT_NOTICE: {
    eng: "© 2024 {{user}}. All rights reserved.",
    ua: "© 2024 {{user}}. Усі права захищені.",
    rus: "© 2024 {{user}}. Все права защищены.",
    fr: "© 2024 {{user}}. Tous droits réservés.",
    de: "© 2024 {{user}}. Alle Rechte vorbehalten.",
  },

  BETA_VERSION_WARNING2: {
    eng: "⚠️ This is a beta version, errors may occur. ",
    ua: "⚠️ Це бета-версія, можливі помилки. ",
    rus: "⚠️ Это бета-версия, возможны ошибки. ",
    fr: "⚠️ Ceci est une version bêta, des erreurs peuvent survenir. ",
    de: "⚠️ Dies ist eine Beta-Version, Fehler können auftreten. ",
  },

  MORE_DETAILS_HERE: {
    eng: "More details here.",
    ua: "Докладніше тут.",
    rus: "Подробнее здесь.",
    fr: "Plus de détails ici.",
    de: "Mehr Details hier.",
  },
  // new
  BACK: {
    eng: "Back",
    ua: "Назад",
    rus: "Назад",
    fr: "Retour",
    de: "Zurück",
  },
  OK_POLICY: {
    eng: "Ok",
    ua: "Ok",
    rus: "Ok",
    fr: "Ok",
    de: "Ok",
  },

  LND_TITLE: {
    eng: "One simple app for managing your events",
    ua: "Єдиний додаток для керування зустрічами",
    rus: "Единое приложение для управления встречами",
    fr: "One simple app for managing your events",
    de: "Eine zentrale Anwendung zur Terminverwaltung",
  },

  LND_TITLE_DESCRIPTION: {
    eng: "With our app, you can easily create, receive, and manage events. It is ideal for both private users and organizations that need to schedule appointments with clients, patients, or partners.",
    ua: "У нашому додатку ви легко можете створювати, отримувати та керувати зустрічами. Він ідеально підходить як для приватних користувачів, так і для організацій, яким потрібно планувати зустрічі з клієнтами, пацієнтами чи партнерами.",
    rus: "В нашем приложении вы можете легко создавать, получать и управлять встречами. Оно идеально подходит как для частных пользователей, так и для организаций, которым необходимо планировать встречи с клиентами, пациентами или партнёрами.",
    fr: "With our app, you can easily create, receive, and manage events. It is ideal for both private users and organizations that need to schedule appointments with clients, patients, or partners.",
    de: "In unserer Anwendung können Sie ganz einfach Termine erstellen, erhalten und verwalten. Sie eignet sich perfekt sowohl für private Nutzer als auch für Organisationen, die Termine mit Kunden, Patienten oder Partnern planen müssen.",
  },

  LND_QUESTION: {
    eng: "What you can do with the app:",
    ua: "Що ви можете робити за допомогою додатку:",
    rus: "Что вы можете делать с помощью приложения:",
    fr: "What you can do with the app:",
    de: "Was Sie mit der App tun können:",
  },

  LND_CONTACT: {
    eng: "",
    ua: "",
    rus: "",
    fr: "",
    de: "",
  },

  LND_CARD_TITLE1: {
    eng: "Create event",
    ua: "Створіть зустріч",
    rus: "Создайте встречу",
    fr: "Create event",
    de: "Termin erstellen",
  },

  LND_CARD_DESCRIPTION1: {
    eng: "Create events — whether it’s a doctor’s appointment, personal meeting, or an event.",
    ua: "Створювати зустрічі — будь то візит до лікаря, особиста зустріч чи подія.",
    rus: "Создавать встречи — будь то визит к врачу, личная встреча или мероприятие.",
    fr: "Create events — whether it’s a doctor’s appointment, personal meeting, or an event.",
    de: "Termine erstellen — sei es ein Arztbesuch, ein persönliches Treffen oder eine Veranstaltung.",
  },

  LND_CARD_TITLE2: {
    eng: "Join event",
    ua: "Підпишіться на зустріч",
    rus: "Подпишитесь на встречу",
    fr: "Join event",
    de: "Termin abonnieren",
  },

  LND_CARD_DESCRIPTION2: {
    eng: "Join events created by other users or organizations.",
    ua: "Підписуватися на зустрічі, створені іншими користувачами чи організаціями.",
    rus: "Подписываться на встречи, созданные другими пользователями или организациями.",
    fr: "Join events created by other users or organizations.",
    de: "Sich für Termine anmelden, die von anderen Nutzern oder Organisationen erstellt wurden.",
  },

  LND_CARD_TITLE3: {
    eng: "Share event",
    ua: "Поділіться зустріччю",
    rus: "Поделитесь встречей",
    fr: "Share event",
    de: "Termin teilen",
  },

  LND_CARD_DESCRIPTION3: {
    eng: "Share your events — you can send a link, QR code, or short code to quickly invite participants.",
    ua: "Ділитися своїми зустрічами — ви можете надіслати посилання, QR-код або короткий код для швидкого додавання учасників до вашої зустрічі.",
    rus: "Делиться своими встречами — вы можете отправлять ссылку, QR-код или короткий код для быстрого добавления участников к вашей встрече.",
    fr: "Share your events — you can send a link, QR code, or short code to quickly invite participants.",
    de: "Eigene Termine teilen — Sie können einen Link, einen QR-Code oder einen kurzen Code versenden, um Teilnehmer schnell hinzuzufügen.",
  },

  LND_CARD_TITLE4: {
    eng: "Assign via key",
    ua: "Призначте зустріч через ключ",
    rus: "Назначьте встречу через ключ",
    fr: "Assign via key",
    de: "Termin per Schlüssel vergeben",
  },

  LND_CARD_DESCRIPTION4: {
    eng: "Allow others to schedule events for you via special “keys”. For example, you can give a trusted person (relative, colleague, assistant) access to add events for you — convenient when someone schedules you a doctor’s visit.",
    ua: `Призначати зустрічі іншим через спеціальні "ключі". Наприклад, ви можете дати довіреній особі (родичу, колезі, секретарю) доступ для додавання вам зустрічей. Це зручно, якщо хтось записує вас до лікаря або на прийом.`,
    rus: `Назначать встречи другим через специальные "ключи". Например, вы можете дать доверенному лицу (родственнику, коллеге, секретарю) доступ для добавления вам встреч. Это удобно, если кто-то записывает вас к врачу или на приём.`,
    fr: "Allow others to schedule events for you via special “keys”. For example, you can give a trusted person (relative, colleague, assistant) access to add events for you — convenient when someone schedules you a doctor’s visit.",
    de: "Anderen die Möglichkeit geben, Ihnen Termine über spezielle „Schlüssel“ zuzuweisen. Zum Beispiel können Sie einer Vertrauensperson (Verwandter, Kollege, Sekretär) Zugriff geben, um für Sie Termine einzutragen. Das ist praktisch, wenn jemand Sie beispielsweise beim Arzt einträgt.",
  },

  LND_CARD_TITLE5: {
    eng: "Organization events",
    ua: "Організаційні зустрічі",
    rus: "Организационные встречи",
    fr: "Organization events",
    de: "Organisationstermine",
  },

  LND_CARD_DESCRIPTION5: {
    eng: "Let organizations assign events to you directly. Just give them a key once, and they can plan events for you.",
    ua: `Надати організаціям можливість призначати вам зустрічі. Достатньо одного разу надати організації "ключ", і вони зможуть планувати зустрічі для вас напряму.`,
    rus: `Предоставлять организациям возможность назначать вам встречи. Достаточно один раз выдать организации "ключ", и они смогут планировать встречи для вас напрямую.`,
    fr: "Let organizations assign events to you directly. Just give them a key once, and they can plan events for you.",
    de: "Organisationen die Möglichkeit geben, Ihnen direkt Termine zuzuweisen. Es reicht aus, der Organisation einmal einen „Schlüssel“ zu geben, und sie kann Termine für Sie planen.",
  },

  LND_CARD_TITLE6: {
    eng: "View participants",
    ua: "Перегляд учасників",
    rus: "Просмотр участников",
    fr: "View participants",
    de: "Teilnehmer anzeigen",
  },

  LND_CARD_DESCRIPTION6: {
    eng: "View participants of all events you are subscribed to or have created yourself.",
    ua: "Переглядати учасників усіх зустрічей, на які ви підписані або які створили самостійно.",
    rus: "Просматривать участников всех встреч, к которым вы подписаны или которые вы сами создали.",
    fr: "View participants of all events you are subscribed to or have created yourself.",
    de: "Die Teilnehmer aller Termine einsehen, an denen Sie teilnehmen oder die Sie selbst erstellt haben.",
  },

  START: {
    eng: "Start",
    ua: "Розпочати",
    rus: "Начать",
    fr: "Commencer",
    de: "Starten",
  },
  SELECT_LNG: {
    eng: "Language selection",
    ua: "Вибір мови",
    rus: "Выбор языка",
    fr: "Sélection de la langue",
    de: "Sprachauswahl",
  },
};

export const parsLanguage = (text = "NONE404", lng = "eng", variables = {}) => {
  let translatedText = AllText?.[text]?.[lng]
    ? AllText?.[text]?.[lng]
    : AllText.NONE404[lng];

  Object.entries(variables).forEach(([key, value]) => {
    translatedText = translatedText.replace(`{{${key}}}`, value);
  });

  return translatedText;
};
