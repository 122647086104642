import { useContext } from "react";
import { ContextStore } from "context/ContextStore";
import { parsLanguage } from "utils/translater";

export const useTranslate = () => {
  const { lng } = useContext(ContextStore);
  return {
    t: (text, variables) => parsLanguage(text, lng, variables),
  };
};
