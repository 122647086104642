import { Typography } from "@mui/material";

import { useTranslate } from "hooks";

const Copyright = () => {
  const { t } = useTranslate();

  return (
    <Typography variant="body2" color="gray.neutral">
      {t("COPYRIGHT_NOTICE", { user: t("MAIN_HEADER") })}
    </Typography>
  );
};

export default Copyright;
