import { useState, useEffect, useContext } from "react";

import { ContextStore } from "context/ContextStore";
import { useHttp } from "hooks";

export const useKeyPage = () => {
  const [keys, setKeys] = useState([]);
  const [filteredKeys, setFilteredKeys] = useState([]);
  const [filter, setFilter] = useState("all");

  const [isOpenModalQrReader, setIsOpenModalQrReader] = useState(false);
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);

  const { request } = useHttp();
  const { token, userId, notif, socket_triger } = useContext(ContextStore);

  useEffect(() => {
    getDataTable();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { action } = socket_triger;
    if (
      action === "key_deleted" ||
      action === "participant_left_key" ||
      action === "key_updated" ||
      action === "new_participant_key"
    ) {
      getDataTable();
    }
    // eslint-disable-next-line
  }, [socket_triger]);

  useEffect(() => {
    keysFilter(filter, keys);
    // eslint-disable-next-line
  }, [keys, filter]);

  const handleSubmit = async (formData) => {
    try {
      await request(`/api/keys/addKey`, "POST", formData, {
        Authorization: `Bearer ${token}`,
      });
      getDataTable();
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const getDataTable = async () => {
    try {
      const res = await request(`/api/keys/getAll`, "GET", null, {
        Authorization: `Bearer ${token}`,
      });
      setKeys(res);
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const fetchDelKey = async (id) => {
    try {
      const { message } = await request(
        `/api/keys/deleteKey/${id}`,
        "DELETE",
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      getDataTable();
      notif(message, "alert");
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const fetchSaveEditKey = async (formData, id) => {
    try {
      await request(`/api/keys/editKey/${id}`, "POST", formData, {
        Authorization: `Bearer ${token}`,
      });
      getDataTable();
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const fetchAddEventForKey = async (formData) => {
    try {
      await request(`/api/events/useKey`, "POST", formData, {
        Authorization: `Bearer ${token}`,
      });
      getDataTable();
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const handleTabChange = (event, newValue) => {
    setFilter(newValue);
  };

  const keysFilter = (valueFilter, arr) => {
    if (valueFilter === "my") {
      setFilteredKeys(arr.filter((item) => item.owner === userId));
    } else if (valueFilter === "friends") {
      setFilteredKeys(arr.filter((item) => item.owner !== userId));
    } else {
      setFilteredKeys(arr);
    }
  };

  const handleAddButton = () => {
    setIsOpenModalCreate(true);
  };

  const closeModalCreate = () => {
    setIsOpenModalCreate(false);
  };

  const handleQrButton = () => {
    setIsOpenModalQrReader(true);
  };

  const closeModalQrReader = () => {
    setIsOpenModalQrReader(false);
  };

  return {
    filteredKeys,
    filter,
    userId,
    handleSubmit,
    fetchDelKey,
    fetchSaveEditKey,
    fetchAddEventForKey,
    handleTabChange,
    getDataTable,
    isOpenModalCreate,
    isOpenModalQrReader,
    handleQrButton,
    handleAddButton,
    closeModalCreate,
    closeModalQrReader,
  };
};
