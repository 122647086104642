import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { BottomNavigation, BottomNavigationAction } from "@mui/material";

import { IconMuiDraw } from "components/Elements";

import { useTranslate } from "hooks/useTranslate.hook";

export default function NavMenu({ navConfig }) {
  const [value, setValue] = useState();

  const { t } = useTranslate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={handleChange}
      sx={{
        backgroundColor: (theme) => theme.palette.dark.main,
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: "56px",
        zIndex: "1000",
      }}
    >
      {navConfig.map(({ link, text, icon, counter }) => (
        <BottomNavigationAction
          key={link}
          label={t(`${text}`)}
          value={link}
          icon={<IconMuiDraw name={icon} />}
          LinkComponent={NavLink}
          to={link}
          sx={{
            color: (theme) => theme.palette.light.main,
            "&.Mui-selected": {
              color: (theme) => theme.palette.light.main,
            },
            "&.active": {
              color: (theme) => theme.palette.shief.light,
            },
          }}
        />
      ))}
    </BottomNavigation>
  );
}
