import { useState, useEffect, useContext, useMemo } from "react";

import { ContextStore } from "context/ContextStore";

import { Box, Paper, Stack, Typography } from "@mui/material";

import {
  SelectLanguage,
  BellButton,
  ModalList,
  Logo,
  BetaVersionText,
} from "components/Elements";

import { useTranslate, useHttp } from "hooks";

import { initSocket, getSocket } from "utils/socket";

function Header({ changeLanguage, lng }) {
  const [isOpenModalBell, setIsOpenModalBell] = useState(false);
  const [listAlerts, setListAlerts] = useState([]);
  const { t } = useTranslate();
  const { request } = useHttp();
  const { token, setSocket_triger, socket_triger } = useContext(ContextStore);

  const isAuthenticated = !!token;

  useEffect(() => {
    getAlerts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      initSocket();

      const socket = getSocket();
      if (!socket) {
        return;
      }

      socket.on("serverEvent", (data) => {
        actionSocket(data);
      });

      return () => {
        socket.off("serverEvent");
      };
    }
    // eslint-disable-next-line
  }, [isAuthenticated, token]);

  useEffect(() => {
    const { action } = socket_triger;
    if (action === "new_participant") {
      getAlerts();
    }
    // eslint-disable-next-line
  }, [socket_triger]);

  const actionSocket = (data) => {
    const { action } = data;

    switch (action) {
      case "event_canceled":
      case "event_deleted":
      case "removed_from_event":
      case "new_participant":
      case "new_participant_hotkey":
      case "hotkey_used":
      case "key_deleted":
      case "participant_left_key":
      case "key_updated":
      case "new_participant_key":
      case "participant_left":
        setSocket_triger(data);
        break;

      default:
        break;
    }
  };

  const getAlerts = async () => {
    try {
      const { notifications } = await request(
        `/api/events/getNotifications`,
        "GET",
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      setListAlerts(notifications);
    } catch (e) {}
  };

  const handleDelAlert = async (id) => {
    try {
      const { notifications } = await request(
        `/api/events/deleteNotification/${id}`,
        "DELETE",
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setListAlerts(notifications);
    } catch (e) {}
  };

  const clearAllAlerts = async () => {
    try {
      await request(`/api/events/delAllNotification`, "DELETE", null, {
        Authorization: `Bearer ${token}`,
      });
      setListAlerts([]);
    } catch (e) {}
  };

  const handleReadAlert = async (id) => {
    try {
      const { notifications } = await request(
        `/api/events/markNotificationAsRead/${id}`,
        "POST",
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setListAlerts(notifications);
    } catch (e) {}
  };

  const calcCounter = useMemo(
    () => listAlerts.reduce((acc, item) => acc + Number(!item.read), 0),
    [listAlerts]
  );

  const handleOpenBell = () => {
    setIsOpenModalBell(true);
  };
  const handleCloseBell = () => {
    setIsOpenModalBell(false);
  };

  return (
    <>
      <Box
        sx={{
          flex: "0 0 auto",
          zIndex: "10",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          // height: "56px", //nobeta-version
          height: "102px",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            height: "102px", //nobeta-version -delete
          }}
        >
          <BetaVersionText
            sx={{
              fontSize: "12px",
              textAlign: "center",
              padding: "12px 12px 0 12px",
            }}
          />
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            backgroundColor="var(--white)"
            p="12px 12px"
          >
            <Logo height="32" />
            <Typography
              variant="h5"
              component="h2"
              color="headerColor"
              sx={{ flexGrow: 1 }}
            >
              {t("MAIN_HEADER")}
            </Typography>

            <SelectLanguage changeLanguage={changeLanguage} lng={lng} />

            <BellButton
              data={listAlerts}
              counter={calcCounter}
              handleBellButton={handleOpenBell}
            />
          </Stack>
        </Paper>
      </Box>
      {isOpenModalBell && (
        <ModalList
          clearAllAlerts={clearAllAlerts}
          handleDelAlert={handleDelAlert}
          open={isOpenModalBell}
          closeModal={handleCloseBell}
          listAlerts={listAlerts}
          handleReadAlert={handleReadAlert}
        />
      )}
    </>
  );
}

export default Header;
