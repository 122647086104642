import { useContext } from "react";
import { ContextStore } from "context/ContextStore";
import { parsLanguage } from "utils/gdrp";

export const useGdrp = () => {
  const { lng } = useContext(ContextStore);
  return {
    tg: (text, variables) => parsLanguage(text, lng, variables),
  };
};
