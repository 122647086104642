import { Badge } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";

const CounterParticipants = ({ counter, sx }) => {
  return (
    <Badge
      badgeContent={counter}
      color="error"
      sx={{
        p: 0,
        opacity: "50%",
        "& .MuiBadge-badge": {
          right: -5,
          top: 6,
        },
        ...sx,
      }}
    >
      <PeopleIcon color="shief" />
    </Badge>
  );
};

export default CounterParticipants;
