import { useContext } from "react";
import { ContextStore } from "context/ContextStore";

import { useLocation, useNavigate } from "react-router-dom";

import { Stack, Button, Box } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { useGdrp, useTranslate } from "hooks";

import { policyType } from "utils/common";

import { SelectLanguage } from "components/Elements";

export default function PolicyPage({ isAuthentificated }) {
  const { tg } = useGdrp();
  const { t } = useTranslate();

  const { lng, changeLanguage } = useContext(ContextStore);

  const navigate = useNavigate();

  const location = useLocation();
  const { type } = location.state || {};

  let content = policyType(type);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      {!isAuthentificated && (
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: "10",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: "56px",
            padding: "12px 12px 0 12px",
            backgroundColor: (theme) => theme.palette.light.main,
          }}
          mb={2}
        >
          <Button
            variant="text"
            color="dark"
            sx={{ textTransform: "none" }}
            startIcon={<KeyboardBackspaceIcon />}
            onClick={handleBack}
          >
            {t("BACK")}
          </Button>
          <SelectLanguage changeLanguage={changeLanguage} lng={lng} />
        </Stack>
      )}
      <Box
        mt={!isAuthentificated && "56px"}
        dangerouslySetInnerHTML={{ __html: tg(content) }}
      ></Box>
      <Box mt={2} sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          type="button"
          onClick={handleBack}
          color="shief"
        >
          {t("OK_POLICY")}
        </Button>
      </Box>
    </div>
  );
}
