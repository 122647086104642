import { useState, useContext } from "react";

import { ContextStore } from "context/ContextStore";

import { Link as RouterLink } from "react-router-dom";

import { Box, Button, Stack, TextField, Typography } from "@mui/material";

import { isMobile } from "react-device-detect";

import {
  Copyright,
  LinkPolicy,
  SelectLanguage,
  Logo,
} from "components/Elements";

import { useTranslate, useHttp } from "hooks";

import cn from "classnames";

import classes from "./AboutPage.module.scss";

const AboutPage = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const { t } = useTranslate();

  const { request } = useHttp();

  const { token, notif, lng, changeLanguage } = useContext(ContextStore);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!email || !name || !message) {
      setValidationErrors({
        email: !email,
        name: !name,
        message: !message,
      });
      return;
    }

    setValidationErrors({});
    handleSupportReport({ email, name, message });
  };

  const handleSupportReport = async (supportData) => {
    try {
      const { message } = await request(
        "/api/auth/supportReport",
        "POST",
        supportData,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      notif(message, "alert");
    } catch (e) {}
  };

  return (
    <>
      <header className={classes.hero}>
        <div className={classes.logo}>
          <Logo height="42" /> <p className={classes.logoText}>TerminNeu</p>
        </div>
        <Stack
          direction="row"
          spacing={4}
          sx={{
            alignItems: "center",
            position: "absolute",
            top: "20px",
            right: "20px",
            zIndex: "2",
          }}
        >
          <Box sx={{ textAlign: "right" }}>
            <Typography
              variant="body2"
              sx={{
                mb: "8px",
                color: "white",
              }}
            >
              {t("SELECT_LNG")}
            </Typography>
            <SelectLanguage changeLanguage={changeLanguage} lng={lng} />
          </Box>
        </Stack>
        <div
          className={cn(
            classes.heroContent,
            isMobile && classes.heroContentMobile
          )}
        >
          <p className={classes.title}>{t("LND_TITLE")}</p>
          <p className={classes.lndDiscription}>{t("LND_TITLE_DESCRIPTION")}</p>
          <Button
            component={RouterLink}
            to="/login"
            variant="contained"
            size="large"
            color="shief"
          >
            {t("START")}
          </Button>
        </div>
      </header>
      <section className={classes.featuresSection}>
        <p className={classes.question}> {t("LND_QUESTION")}</p>
        <div className={classes.featuresContainer}>
          <div className={classes.featureItem}>
            <div className={classes.icon}>📅</div>
            <h3 className={classes.featureTitle}>{t("LND_CARD_TITLE1")}</h3>
            <p className={classes.featureText}>{t("LND_CARD_DESCRIPTION1")}</p>
          </div>
          <div className={classes.featureItem}>
            <div className={classes.icon}>✅</div>
            <h3 className={classes.featureTitle}>{t("LND_CARD_TITLE2")}</h3>
            <p className={classes.featureText}>{t("LND_CARD_DESCRIPTION2")}</p>
          </div>
          <div className={classes.featureItem}>
            <div className={classes.icon}>🔗</div>
            <h3 className={classes.featureTitle}>{t("LND_CARD_TITLE3")}</h3>
            <p className={classes.featureText}>{t("LND_CARD_DESCRIPTION3")}</p>
          </div>
          <div className={classes.featureItem}>
            <div className={classes.icon}>🔑</div>
            <h3 className={classes.featureTitle}>{t("LND_CARD_TITLE4")}</h3>
            <p className={classes.featureText}>{t("LND_CARD_DESCRIPTION4")}</p>
          </div>
          <div className={classes.featureItem}>
            <div className={classes.icon}>🏢</div>
            <h3 className={classes.featureTitle}>{t("LND_CARD_TITLE5")}</h3>
            <p className={classes.featureText}>{t("LND_CARD_DESCRIPTION5")}</p>
          </div>
          <div className={classes.featureItem}>
            <div className={classes.icon}>👥</div>
            <h3 className={classes.featureTitle}>{t("LND_CARD_TITLE6")}</h3>
            <p className={classes.featureText}>{t("LND_CARD_DESCRIPTION6")}</p>
          </div>
        </div>
        <Button
          component={RouterLink}
          to="/login"
          variant="contained"
          size="large"
          color="shief"
        >
          {t("START")}
        </Button>
      </section>
      <div className={classes.mailWrap}>
        <form onSubmit={handleSubmit}>
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={3}
            sx={{
              mt: "18px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "white",
              }}
            >
              {t("SUPPORT_TITLE")}
            </Typography>
            <Stack
              direction="row"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              <TextField
                label={t("EMAIL")}
                variant="standard"
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                error={validationErrors.email}
                helperText={validationErrors.email && t("ERROR_EMAIL_REQ")}
                sx={{
                  width: isMobile ? "100%" : "250px",
                  "& label": {
                    color: "#bbb",
                  },
                  "& label.Mui-focused": {
                    color: "#fff",
                  },
                  "& .MuiInputBase-input": {
                    color: "#fff",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#999",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#fff",
                  },
                }}
              />
              <TextField
                label={t("NAME")}
                variant="standard"
                type="text"
                name="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                error={validationErrors.name}
                helperText={validationErrors.name && t("ERROR_NAME_REQ")}
                sx={{
                  width: isMobile ? "100%" : "250px",
                  "& label": {
                    color: "#bbb",
                  },
                  "& label.Mui-focused": {
                    color: "#fff",
                  },
                  "& .MuiInputBase-input": {
                    color: "#fff",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#999",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#fff",
                  },
                }}
              />
              <TextField
                multiline
                rows={1}
                label={t("MESSAGE_SUPPORT")}
                variant="standard"
                type="text"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                error={validationErrors.message}
                helperText={validationErrors.message && t("ERROR_MESSAGE_REQ")}
                inputProps={{
                  maxLength: 1000,
                }}
                sx={{
                  width: isMobile ? "100%" : "250px",
                  "& label": {
                    color: "#bbb",
                  },
                  "& label.Mui-focused": {
                    color: "#fff",
                  },
                  "& .MuiInputBase-input": {
                    color: "#fff",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#666",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#999",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#fff",
                  },
                }}
              />
            </Stack>
            <Button type="submit" color="light">
              {t("SEND")}
            </Button>
          </Stack>
        </form>
      </div>
      <Box>
        <LinkPolicy />
        <Copyright />
      </Box>
    </>
  );
};

export default AboutPage;
