import { IconButton, Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

const BellButton = ({ data, counter, handleBellButton }) => {
  const isDisabled = !data?.length;

  return (
    <div>
      <IconButton
        disabled={isDisabled}
        sx={{ p: 0, opacity: isDisabled ? "50%" : "100%" }}
        onClick={handleBellButton}
        color="shief"
      >
        <Badge badgeContent={counter} color="error" overlap="circular">
          <NotificationsIcon
            size="large"
            sx={{
              width: "30px",
              height: "30px",
            }}
          />
        </Badge>
      </IconButton>
    </div>
  );
};

export default BellButton;
