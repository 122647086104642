import { Link as RouterLink } from "react-router-dom";

import { Link, Typography } from "@mui/material";

import { useTranslate } from "hooks";

function BetaVersionText({ sx }) {
  const { t } = useTranslate();

  return (
    <Typography variant="body2" sx={sx}>
      {t("BETA_VERSION_WARNING2")}
      <Link
        component={RouterLink}
        to="/policy"
        state={{ type: "beta-disclaimer" }}
      >
        {t("MORE_DETAILS_HERE")}
      </Link>
    </Typography>
  );
}

export default BetaVersionText;
